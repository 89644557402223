/*!
 * 基于 animate.css - https://animate.style/ 删改，只保留 常用关键的配置，请勿再次修改，会影响到popup.service！
 */

.animate__animated {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__animated.animate__fill_none {
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
}

.animate__animated.animate__faster {
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
}

.animate__animated.animate__fast {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
}

.animate__animated.animate__slow {
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
}

.animate__animated.animate__slower {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
}

.animate__fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

.animate__fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

.animate__fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }
}

.animate__fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  to {
    opacity: 0
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  to {
    opacity: 0
  }
}

.animate__zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut
}

@-webkit-keyframes zoomIn-light {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.6, .6, .6);
    transform: scale3d(.6, .6, .6)
  }

  50% {
    opacity: 1
  }
}

@keyframes zoomIn-light {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.6, .6, .6);
    transform: scale3d(.6, .6, .6)
  }

  50% {
    opacity: 1
  }
}

.animate__zoomIn_light {
  -webkit-animation-name: zoomIn-light;
  animation-name: zoomIn-light
}

@-webkit-keyframes zoomOut-light {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.6, .6, .6);
    transform: scale3d(.6, .6, .6)
  }

  to {
    opacity: 0
  }
}

@keyframes zoomOut-light {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.6, .6, .6);
    transform: scale3d(.6, .6, .6)
  }

  to {
    opacity: 0
  }
}

.animate__zoomOut_light {
  -webkit-animation-name: zoomOut-light;
  animation-name: zoomOut-light
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: hidden
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: hidden
  }
}

.animate__slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: hidden
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: hidden
  }
}

.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden
  }
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden
  }
}

.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: hidden
  }
}

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: hidden
  }
}

.animate__slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp
}
